.team-page {
  text-align: center;
  padding: 2rem;
  background-color: #f8f8f8;
}

.team-header h1 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
}

.team-container {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 5rem;
  flex-wrap: wrap;
}

.team-member {
  position: relative;
  width: 25%;
  text-align: center;
}

.team-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.team-info {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 1rem;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.team-info h2 {
  font-size: 1.5rem;
  margin: 0;
}

.team-info p {
  margin: 0;
  font-size: 1rem;
}

@media (max-width: 768px) {
  .team-member {
    width: 45%;
  }

  .team-info h2 {
    font-size: 1.2rem;
  }

  .team-info p {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .team-member {
    width: 90%;
    margin-bottom: 1rem;
  }

  .team-info h2 {
    font-size: 1rem;
  }

  .team-info p {
    font-size: 0.8rem;
  }
}
