.testimonial-section {
    position: relative;
    text-align: center;
    color: white;
    height: 80vh; /* Adjust the height as needed */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .testimonial-background {
    position: relative;
    width: 100%; 
    height: 100%;
    background-image: url('../../public/assets/Group train.webp');
    background-size: cover;
    background-position: center; 
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    clip-path: url(#curve-path);
  }
  
  .curve {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: -1; /* Ensure the SVG is behind the content */
  }
  
  .testimonial-content {
    position: relative;
    padding: 2rem;
    background: rgba(0, 0, 0, 0.6); /* Optional: To add a darker overlay on the image */
    border-radius: 8px;
  }
  
  .testimonial-text {
    max-width: 600px;
    margin: auto;
  }
  
  .testimonial-text p {
    font-size: 1.5rem;
    line-height: 1.5;
  }
  
  .testimonial-text .quote {
    font-size: 2rem;
  }
  
  .author {
    margin-top: 1rem;
    font-style: italic;
  }
  