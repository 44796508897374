* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 2rem;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.nav-left {
  position: absolute;
  left: 2rem;
  display: flex;
  align-items: center;
}

.nav-logo {
  background-image: url('../../public/assets/ramp-icon-update.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 50px;
  width: 50px;
  border: none;
  cursor: pointer;
}

.nav-links {
  display: flex;
  align-items: center;
  z-index: 1000; /* Add this line if needed to stop header stacking issues*/
}

.nav-links ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.nav-link {
  margin-right: 1.5rem;
  text-decoration: none;
  color: rgba(21, 21, 207, 255);
  font-size: 1.2rem;
  position: relative;
}

.nav-link:hover {
  text-decoration: underline;
}

.nav-right {
  position: absolute;
  right: 2rem;
  display: flex;
  align-items: center;
}

.nav-clinic-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.5rem;
}

.book-now {
  padding: 0.5rem 1rem;
  border: 2px solid rgb(255, 255, 255);
  background-color: rgb(26, 218, 113);
  border-radius: 25px;
  text-decoration: none;
  color: rgb(255, 255, 255);
  font-size: 1rem;
  font-weight: bold;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}



.nav-icon {
  margin-right: 1.5rem;
  text-decoration: none;
  color: rgba(21, 21, 207, 255);
  font-size: 1.5rem;
}

.nav-icon:hover {
  text-decoration: underline;
}

.join-now {
  padding: 0.5rem 1rem;
  border: 2px solid rgba(21, 21, 207, 255);
  border-radius: 25px;
  text-decoration: none;
  color: rgba(21, 21, 207, 255);
  font-size: 1rem;
  font-weight: bold;
}

.join-now:hover {
  background-color: rgba(21, 21, 207, 255);
  color: #fff;
}

.menu-toggle {
  display: none;
  font-size: 2rem;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1200; /* Highest z-index due to fixed menu*/
}

.mobile-phone-number {
  display: none;
}

@media (max-width: 1065px) {
  .nav-left {
    left: 1rem;
  }

  .nav-link {
    margin-right: 1rem;
    text-decoration: none;
    color: rgba(21, 21, 207, 255);
    font-size: 1.2rem;
    position: relative;
  }
}

@media (max-width: 980px) {

  .mobile-phone-number {
    display: flex;
    flex-direction: column ;
  }
  .nav-clinic-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0rem;
  }

  .header {
    justify-content: space-between;
    padding: 1rem 2rem;
  }

  .nav-left {
    position: static;
  }

  .nav-links {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-color: white;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1100; /* Increased z-index due to fixed header*/
  }

  .nav-links.mobile-menu-open {
    display: flex;
  }

  .nav-links ul {
    flex-direction: column;
    margin-right: 0rem;
    align-items: center;
  }

  .nav-link {
    margin: 0rem 0;
    font-size: 1.2rem;
  }

  .nav-right {
    position: static;
    flex-direction: column;
    margin-top: 1rem;
  }

  .nav-icon {
    margin: 1rem 0;
    font-size: 2rem;
  }

  .join-now {
    margin-top: 1rem;
    font-size: 1.2rem;
  }

  .menu-toggle {
    display: block;
  }

  .mobile-menu-logo {
    margin-bottom: 2rem;
  }

  .mobile-logo {
    max-width: 100px;
  }
}

.parking-info {
  color: rgba(21, 21, 207, 255);
  font-size: 0.9rem;
  margin-top: 0.5rem;
  line-height: 1;
  white-space: nowrap;
}

@media (max-width: 980px) {
  .parking-info {
    margin: 0.1rem 0;
  }
}