.service-detail-content {
  background-color: #009fe3;
  color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 5rem;
}

.service-detail-text {
  flex: 1;
  padding: 2rem;
}

.service-detail-text h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.service-detail-text h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.service-detail-text p {
  font-size: 1.25rem;
  line-height: 1.6;
  margin-bottom: 1rem;
  padding-bottom: 2rem;
}

.signup-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.signup-form input {
  padding: 0.5rem;
  font-size: 1rem;
  margin-bottom: 1rem;
  border-radius: 4px;
  border: none;
}

.signup-form button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  background-color: #3498db;
  color: white;
  cursor: pointer;
}

.signup-form button:hover {
  background-color: #2980b9;
}

.service-detail-image-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
}

.service-detail-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.service-intro-container1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 8rem;
  background-color: #fff;
  width: 100%;
  flex-direction: row;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.service-intro-content1 {
  flex: 2;
  padding-right: 2rem;
}

.service-intro-content1 h1 {
  color: #009fe3;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.service-intro-content1 p {
  color: #333;
  font-size: 1.2rem;
  line-height: 1.5;
  font-weight: bold;
  line-height: 2.1rem;
}

.service-intro-content1 h3 {
  color: #131313;
  font-size: 1.3rem;
  line-height: 1.5;
  font-weight: bold;
  line-height: 5rem;
}

.service-intro-container2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8rem 8rem;
  background: #2980b9;
  width: 100%;
  flex-direction: row;
  margin-top: 0rem;
  margin-bottom: 1rem;
}

.service-intro-content2 {
  flex: 2;
  padding-right: 0rem;
  padding-left: 2rem;
}

.service-intro-content2 h1 {
  color: #333;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.service-intro-content2 h3 {
  font-size: 1.7rem;
  line-height: 3;
}

.service-intro-content2 p {
  color: #f9f9f9;
  font-size: 1.2rem;
  line-height: 1.5;
}
.cosumerlaw-link {
  text-decoration: none; 
  color: #333;
  font-size: 1.2rem;
  line-height: 1.5;
  font-weight: bold;
}

.service-intro-container3 {
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.service-intro-logo {
  max-width: 200px;
  margin-bottom: 1rem;
}

.service-intro-text {
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 1.6;
  color: #1a5276;
  padding: 2rem 20rem;
}

.service-intro-container4 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8rem 8rem;
  background-color: #002b54;
  width: 100%;
  flex-direction: row;
  margin-top: 0rem;
  margin-bottom: 0rem;
}
.service-intro-content4 {
  flex: 2;
  padding-right: 2rem;
  padding-left: 2rem;
}

.service-intro-content4 h3 {
  color: #fff;
  font-size: 2rem;
  margin: 1rem 1rem;
}

.service-intro-content4 p {
  color: #fff;
  font-size: 1.2rem;
  line-height: 1.5;
  padding: 1rem;

}

.service-intro-content4 li {
  text-align: auto;
  list-style-position: inside;
  font-style: normal;
}

.health-section.servicedetail-margin-top {
  margin-top: 0rem; 
}

.servicedetail-book-now {
  padding: 1rem 2rem;
  border: 2px solid rgb(255, 255, 255);
  background-color: rgb(26, 218, 113);
  border-radius: 25px;
  text-decoration: none;
  color: rgb(255, 255, 255);
  font-size: 1.3rem;
  font-weight: bold;
}

@media (max-width: 768px) {
  .service-detail-text h1 {
    font-size: 2rem;
  }

  .service-detail-text h2 {
    font-size: 1.5rem;
  }

  .service-detail-text p {
    font-size: 1rem;
  }

  .service-detail-image-container {
    padding: 5rem;
  }

  .service-intro-container1,
  .service-intro-container2,
  .service-intro-container4 {
    flex-direction: column;
    padding: 2rem;
  }

  .service-intro-content1,
  .service-intro-content2,
  .service-intro-content4 {
    padding-right: 0;
    padding-left: 0;
  }

  .service-intro-content1 h1,
  .service-intro-content2 h1,
  .service-intro-content4 h1 {
    font-size: 1.5rem;
  }

  .service-intro-content1 p,
  .service-intro-content2 p,
  .service-intro-content4 p {
    font-size: 1rem;
  }

  .service-intro-text {
    padding: 2rem 1rem;
  }

  .news-column h3 {
    padding: 10px;
    background-color: #f5f5f5;
    margin: 5px 0;
    border-radius: 4px;
  }

  .news-column h3:hover {
    background-color: #e5e5e5;
  }

  .service-intro-content1 li,
  .service-intro-content2 li,
  .service-intro-content4 li {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {


  .service-detail-content {
    flex-direction: column;
    padding: 0rem;
  }
  .service-detail-text {
    margin-top: 5rem;
  }
  .service-detail-text h1 {
    font-size: 1.5rem;
  }

  .service-detail-text h2 {
    font-size: 1.25rem;
  }

  .service-detail-text p {
    font-size: 0.9rem;
  }

  .service-detail-image-container {
    padding: 2rem;
  }

  .service-intro-container1,
  .service-intro-container2,
  .service-intro-container4 {
    padding: 2rem;
  }

  .service-intro-content1,
  .service-intro-content2,
  .service-intro-content4 {
    padding: 0;
  }

  .service-intro-content1 h1,
  .service-intro-content2 h1,
  .service-intro-content4 h1 {
    font-size: 1.25rem;
  }

  .service-intro-content1 p,
  .service-intro-content2 p,
  .service-intro-content4 p {
    font-size: 0.9rem;
  }

  .service-intro-text {
    padding: 1rem;
  }

  .service-intro-content1 li,
  .service-intro-content2 li,
  .service-intro-content4 li {
    font-size: 0.9rem;
  }
}
.service-intro-container3 {
  text-align: center;
  padding: 2rem;
}

.service-intro-container3 h2 {
  margin-bottom: 2rem;
}

.service-intro-container3 .news-content {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin: 2rem 0;
}

.service-intro-container3 .news-column {
  flex: 1;
  max-width: 500px;
}

.service-intro-container3 .news-item {
  margin: 1rem 0;
}

.service-intro-container3 .news-item a {
  text-decoration: none;
  color: inherit;
}

.service-intro-container3 .news-item:hover {
  opacity: 0.8;
}
.news-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.news-column {
  display: flex;
  flex-direction: column;
}

.news-column h3 {
  color: #333;
  margin-bottom: 15px;
  font-size: 1.1rem;
}

.news-links {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.news-links a {
  text-decoration: none;
  color: #666;
}

.news-links a:hover {
  color: #000;
}

.news-links p {
  margin: 0;
  font-size: 0.9rem;
}

@media (max-width: 1200px) {
  .news-content {
      grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .news-content {
      grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .news-content {
      grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .news-content.mobile-layout {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .news-column h3 {
    padding: 12px 15px;
    background-color: #f5f5f5;
    margin: 0;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .news-column h3:hover {
    background-color: #e5e5e5;
  }

  .news-links {
    padding: 10px 15px;
  }
}

.service-intro-content1 li {
  color: #333;
  font-size: 1.2rem;
  line-height: 1.5;
  font-weight: bold;
}

.service-intro-content2 li {
  color: #333;
  font-size: 1.2rem;
  line-height: 1.5;
  font-weight: bold;
}

.service-intro-content4 li {
  color: #fff;
  font-size: 1.2rem;
  line-height: 1.5;
  font-weight: bold;
}
