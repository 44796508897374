/* src/App.css */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #fff;
}

#root {
  overflow-x: hidden; /* Prevent horizontal scroll */
}

header {
  width: 100%;
}

nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
}

nav ul li {
  margin-right: 0rem;
}

nav ul li a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
}

nav ul li a:hover {
  text-decoration: underline;
}
