.contact-page {
    text-align: center;
    padding: 0rem;
    margin-top: 10rem;
}

.contact-header-details-container {
    position: relative;
    background: url('../../public/assets/ramp-icon-update.png') no-repeat center center;
    background-size: contain;
    color: black; 
}

.contact-header-details-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.6); 
    z-index: 0;
}

.contact-header {
    margin-bottom: 2rem;
    padding: 2rem;
    z-index: 1;
    position: relative;
}

.contact-header h1 {
    font-size: 3rem;
    color: black; 
    z-index: 2;
    position: relative;
}

.contact-details {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    padding: 2rem 10%;
    z-index: 1;
    position: relative;
    color: black;
}

.contact-info,
.contact-address {
    text-align: center;
    flex: 1;
    font-size: 1.2rem;
    z-index: 2;
    position: relative;
}

.contact-info p,
.contact-address p {
    margin: 0.5rem 0;
}

.contact-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 4rem 10%;
    background-color: #fff;
    width: 100%;
    margin-top: 2rem;
    text-align: left;
}

.contact-form {
    flex: 0 0 45%; 
    margin-right: 0%; /* Space between form and map */
}

.contact-form form {
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 1rem;
}

.form-group label {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
    display: block;
}

.form-group input {
    width: 100%;
    padding: 0.5rem;
    margin-top: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.form-group textarea {
    width: 100%;
    padding: 0.5rem;
    margin-top: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 100px !important;
}

.form-group-inline {
    display: flex;
    justify-content: space-between;
}

.form-group-inline input {
    width: calc(50% - 0.5rem);
}

.form-group-checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.form-group-checkbox input {
    display: none;
}

.form-group-checkbox label {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.checkbox-custom {
    width: 1.5rem;
    height: 1.5rem;
    background-color: white;
    border: 2px solid #002b54;
    border-radius: 0.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;
    transition: background-color 0.3s;
}

.form-group-checkbox input:checked + label .checkbox-custom {
    background-color: #002b54; 
}

.form-group-checkbox input:checked + label .checkbox-custom::before {
    content: '✓';
    font-size: 1rem;
    color: #009fe3;
}

.button-container {
    display: flex;
    justify-content: center;
}

button[type="submit"] {
    padding: 1.2rem 2rem;
    border: 2px solid rgba(21,21,207,255);
    border-radius: 50px;
    text-decoration: none;
    color: rgba(21,21,207,255);
    font-size: 1rem;
    font-weight: bold;
}

button[type="submit"]:hover {
    background-color: rgba(21,21,207,255);
    color: #fff;
}

.map-section {
    flex: 0 0 45%; 
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 768px) {
    .contact-details {
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 0 5%;
    }
  
    .contact-info,
    .contact-address {
      text-align: center;
      flex: none;
      margin-bottom: 1rem;
    }
  
    .contact-section {
      flex-direction: column;
      align-items: center;
      padding: 2rem 5%;
    }
  
    .contact-form {
      width: 100%;
      margin-bottom: 2rem;
    }
  
    .map-section {
      width: 100%;
    }
  
    .form-group-inline {
      flex-direction: column;
    }
  
    .form-group-inline input {
      width: 100%;
      margin-bottom: 1rem;
    }
}
  
@media (max-width: 480px) {
    .contact-header h1 {
      font-size: 2rem;
    }
  
    .contact-details {
      padding: 0 2%;
    }
  
    .contact-section {
      padding: 2rem 2%;
    }
  
    button[type="submit"] {
      padding: 1rem;
      font-size: 1rem;
    }
}
