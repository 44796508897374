.news-section {
  background-color: #002b54; 
  color: white;
  padding: 4rem 2rem;
  text-align: center;
}

.news-header h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  margin-top: 5rem;
}

.news-header p {
  font-size: 1rem;
  margin-bottom: 2rem;
}

.news-content {
  display: flex;
  justify-content: center;
  gap: 4rem;
}

.news-column {
  text-align: center;
}

.news-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.news-item p {
  font-size: 1rem;
  margin: 0.5rem 0;
}

.news-item a {
  color: white; 
  text-decoration: none; 
  position: relative; 
  padding-bottom: 0.2rem; 
  font-weight: bold;
}

.news-item a::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: white; /* The color of the underline */
  transition: width 0.3s ease-in-out; /* Smooth animation for the underline */
}

.news-item a:hover::after {
  width: 100%; 
}
.news-item-separator {
  text-align: center;
  margin: 0.5rem 0;
}

.news-search {
  padding: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  max-width: 400px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.large-return {
  margin-top: 40px;
  font-size: 2.5em;
}

.news-header a {
  color: white; 
  text-decoration: none; 
  position: relative; 
  display: inline-block;
}

.news-header a::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0.5rem;
  left: 0;
  background-color: white;
  transition: width 0.3s ease-in-out;
}

.news-header a:hover::after {
  width: 100%;
}