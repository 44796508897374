/* src/components/Home.css */
.home {
    height: 100vh;
    background-image: url('../../public/assets/Ramp_cover.webp');
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 2rem;
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.text-box {
    background-color: rgba(21, 21, 207, 0.9);
    padding: 1rem 2rem;
    border-top-left-radius: 3rem;
    border-bottom-right-radius: 3rem;
    max-width: 650px;
    text-align: center;
}

.text-box h1 {
    margin: 0;
    font-size: 3rem;
}
.text-box button {
  padding: 0.5rem 1rem;
  border: 2px solid #fff;
  border-radius: 25px;
  text-decoration: none;
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
  background-color: #009fe3;
  transition: background-color 0.3s, color 0.3s;
  margin-bottom: 1rem;
}
.text-box button:hover {
  background-color: #0056b3;
  color: #fff;
}
.cover-book-now {
  padding: 0.5rem 1rem;
  border: 2px solid #fff;
  border-radius: 15px;
  text-decoration: none;
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
  background-color: rgb(26, 218, 113);
  transition: background-color 0.3s, color 0.3s;
  margin-bottom: 1rem;
  text-shadow: none;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  max-width: 400px;
  width: 90%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  outline: none;
  z-index: 3;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 2;
}

.intro {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 8rem;
    background-color: #fff;
    width: 100%;
    flex-direction: row;
    color: #333;
    margin-top: 1rem;
    margin-bottom: 5rem;
}

.intro-content {
    flex: 2;
    padding-right: 2rem;
}

.intro-content h1 {
    color: #009fe3;
    /* Blue color for the heading */
    font-size: 2rem;
    margin-bottom: 1rem;
}

.intro-content p {
    color: #333;
    font-size: 1.2rem;
    line-height: 1.5;
    font-weight: bold;
}

.intro-image {
    flex: 1;
}

.intro-image img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
}

.intro-video {
  max-width: 100%;
  height: auto;
  margin-top: 1rem;
}


.services-section {
    width: 100%;
    background-color: #009fe3;
    /* Blue background color */
    padding: 4rem 2rem;
    position: relative;
    overflow: hidden;
}


.services-header {
    text-align: center;
    color: white;
    margin-bottom: 2rem;
    position: relative;
    z-index: 2;
}

.services-header h2 {
    font-size: 2.5rem;
    margin: 0;
}

.services-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    z-index: 2;
}

.service-item {
    flex: 1 1 calc(25% - 1rem);
    margin: 0.5rem;
    text-align: center;
}

.service-item img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
}

.service-item h3 {
    color: white;
    margin-top: 0.5rem;
    font-size: 1.2rem;
}


.discover-image {
  position: relative;
  background-image: url('../../public/assets/Post.Ramp.July-23.jpg'); 
  background-size: cover;
  background-position: center;
  padding: 4rem 2rem;
  height: 60vh;
}
/* New Styles for Health Section */
.health-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4rem 2rem;
  background-color: #fff; 
  width: 100%;
  margin-top: 2rem;
}

.health-content {
  flex: 2;
  padding-right: 2rem;
}

.health-content h2 {
  color: #009fe3; 
  font-size: 4rem;
  margin-bottom: 1rem;
}

.health-content p {
  color: #333;
  font-size: 1.2rem;
  line-height: 1.5;
    font-weight: bold;
}


.health-form {
  flex: 1;
  display: flex;
  justify-content: center; 
  align-items: center; 
}

.health-form form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
    font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  display: block;
}

.form-group input {
  width: 100%;
  padding: 0.5rem;
  margin-top: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-group-inline {
  display: flex;
  justify-content: space-between;
}

.form-group-inline input {
  width: calc(50% - 0.5rem); 
}

button[type="submit"] {
    padding: 0rem 1rem;
    border: 2px solid #009fe3;
    border-radius: 25px;
    text-decoration: none;
    color: #009fe3;
    font-size: 1rem;
    font-weight: bold;
    max-width: 40%;
    background-color: #fff;
}

button[type="submit"]:hover {
    background-color: #0056b3;
    color: #fff;
}


/* New Styles for Amenities Section */
.amenities-section {
  background-color: #002b54; /* Dark blue background */
  color: white;
  padding: 4rem 2rem;
  text-align: center;
}

.amenities-header h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.amenities-header p {
  font-size: 1rem;
  margin-bottom: 2rem;
}

.amenities-content {
  display: flex;
  justify-content: center;
  gap: 4rem; /* Space between columns */
}

.amenities-column {
  text-align: center;
}


.amenities-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.amenities-item p {
  font-size: 1rem;
  margin: 0.5rem 0;
}
.amenities-item a {
  color: white; 
  text-decoration: none; 
  position: relative; 
  padding-bottom: 0.2rem; 
  font-weight: bold;
}

.amenities-item a::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: white; /* The color of the underline */
  transition: width 0.3s ease-in-out; /* Smooth animation for the underline */
}

.amenities-item a:hover::after {
  width: 100%; 
}

.floating-bar {
  position: fixed;
  top: 90%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 0px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 3;
}

.floating-logo {
  height: 40px;
  margin-right: 0px;
}

.floating-button {
  padding: 12.2px 20px;
  background-color: #009fe3;
  color: white;
  border: none;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 3;
}

.floating-button:hover {
  background-color: #007bb3;
}


.floating-button:hover {
  background-color: #007bb3;
}

@media (max-width: 768px) {

  .intro {
    flex-direction: column;
    padding: 2rem;
  }

  .intro-content {
    padding-right: 0;
    margin-bottom: 2rem;
  }

  .intro-content h1 {
    font-size: 1.5rem;
  }

  .intro-content p {
    font-size: 1rem;
  }
  .intro-image {
    width: 100%;
  }
  .health-section {
    flex-direction: column;
    align-items: flex-start;
  }

  .health-content {
    padding-right: 0;
    margin-bottom: 2rem;
  }

  .health-content h2 {
    font-size: 3rem;
  }

  .health-content p {
    font-size: 1rem;
  }

  .form-group-inline {
    flex-direction: column;
  }

  .health-section .form-group-inline input {
    width: 100%;
    margin-bottom: 1rem;
  }
  

  button[type="submit"] {
    max-width: 100%;
  }
}

@media (max-width: 480px) {

  .home {
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0;
    background-image: url('../../public/assets/Ramp_cover.webp');
    background-size: 180%;
    background-position: center;
  }

  .text-box {
    background-color: rgba(21, 21, 207, 0.9);
    padding: 1rem;
    border-top-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    max-width: 95%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .text-box button {
    font-size: 1rem; 
    padding: 0.5rem; 
    border-radius: 15px; 
    width: 90%;
    margin-bottom: 0.5rem;
}
.cover-book-now {
  font-size: 1rem; 
  padding: 0.5rem; 
  border-radius: 10px; 
  width: 40%;
  margin-bottom: 0rem;

}

  .text-box h1 {
    font-size: 2rem;
    margin: 0;
  }

  .health-content h2 {
    font-size: 2.5rem;
  }

  .health-content p {
    font-size: 0.9rem;
  }
  .health-form {
    flex: 1;
    width: 100%;
}

  .form-group label {
    font-size: 1rem;
  }

  .form-group input {
    padding: 0.5rem;
  }

  button[type="submit"] {
    padding: 0.5rem;
    font-size: 1rem;
  }
}
/* .birdeye-widget-container {
  margin: 2rem;
  max-width: 800px;
  padding: 0 20px;
} */

.reviews-section {
    padding: 40px 0;
    background-color: #fff;
    width: 100%;
}

.birdeye-widget-container {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 20px;
}