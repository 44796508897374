.NDIS_category-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 3rem 2rem;
  text-align: center;
  background: #fff;
}

.NDIS_category-logo {
  max-width: 200px;
  margin-bottom: 2rem;
}

.NDIS_category-text {
  max-width: 800px;
  margin: 0 auto 3rem;
  font-size: 1.8rem;
  color: #2c3e50;
  line-height: 1.5;
  text-align: left;
  padding: 0 1rem;
}

.NDIS_category-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 2.5rem;
  margin: 3rem 0;
}

.NDIS_category-column {
  background: #ffffff;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s ease;
  border: 1px solid #eef2f7;
}

.NDIS_category-column:hover {
  transform: translateY(-5px);
}

.NDIS_category-column h3 {
  color: #1a365d;
  font-size: 1.5rem;
  margin-bottom: 1.8rem;
  padding-bottom: 0.8rem;
  border-bottom: 2px solid #e2e8f0;
  font-weight: 600;
}

.NDIS_category-links {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}

.NDIS_category-links a {
  text-decoration: none;
  color: #4a5568;
  transition: all 0.2s ease;
  padding: 0.5rem;
  border-radius: 6px;
}

.NDIS_category-links a:hover {
  background-color: #f7fafc;
  color: #2b6cb0;
  transform: translateX(5px);
}

.NDIS_category-links p {
  margin: 0;
  font-size: 1.05rem;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .NDIS_category-content {
    grid-template-columns: 1fr;
    gap: 1rem;
    width: 100%;
  }
  
  .NDIS_category-column {
    margin: 0;
    width: 100%;
    padding: 1rem;
  }
  
  .NDIS_category-column h3 {
    text-align: left;
    width: 100%;
    margin: 0 0 1rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .NDIS_category-container {
    padding: 1rem;
    width: 100%;
  }
  
  .NDIS_category-text {
    font-size: 1.2rem;
    padding: 0 1rem;
    text-align: left;
    width: 100%;
    margin: 2rem auto;
  }

  .NDIS_category-mobile-layout {
    width: 100%;
    padding: 0;
  }
}