.services-header h1 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    color: blue;
}

.services-container {
    display: flex;
    justify-content: center;
    gap: 1rem;
    flex-wrap: wrap;
    padding: 2rem 4rem;
}

.service-item {
    position: relative;
    text-align: center;
    transition: transform 0.3s;
    flex: 1 1 calc(22% - 1rem); 
    box-sizing: border-box;
}

.service-item a {
    color: inherit;
    text-decoration: none;
}

.service-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    transition: transform 0.3s;
}

.service-info {
    margin-top: 1rem;
}

.service-info h2 {
    font-size: 1.5rem;
    margin: 0;
}

.service-item:hover .service-image {
    transform: scale(1.05);
}

@media (max-width: 480px) {
    .service-item {
        flex: 1 1 100%; /* One item per row on extra small screens */
    }
}
