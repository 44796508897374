.thank-you-page {
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background-color: #f5f5f5;
}

.thank-you-content {
  text-align: center;
  background-color: white;
  padding: 3rem;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
}

.thank-you-content h1 {
  color: #333;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
}

.thank-you-content p {
  color: #666;
  margin-bottom: 1rem;
  line-height: 1.6;
}

.back-home-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 12px 30px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.back-home-button:hover {
  background-color: #0056b3;
}

.button-group {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.news-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 12px 30px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.news-button:hover {
  background-color: #0056b3;
}

.phone-link {
  text-decoration: none;
  font-weight: 500;
}

.phone-link:hover {
  text-decoration: underline;
}