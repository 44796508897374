.banner-section {
    display: flex;
    flex-direction: column;
  }
.banner-container {
    width: 100%;
    overflow: hidden;
    position: relative;
  }
  
  .banner {
    display: flex;
    width: max-content; 
    animation: scroll 30s linear infinite; /* Adjust the duration for speed */
  }
  .reverse .banner {
    animation-direction: reverse; /* Reverses the direction for the second row */
  }
  
  .banner-item {
    flex-shrink: 0;
    width: 200px; /* Adjust according to logo size */
    margin-right: 0px; 
  }
  
  .banner img {
    width: 100%;
    height: 95px;
    display: block;
    pointer-events: none;
    background-color: white;
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-33.3%);
    }
  }
  
  /* Responsive Design */
@media (max-width: 768px) {
  .banner {
      animation: scroll 40s linear infinite; /* Slower scroll for mobile */
  }
  .banner-item {
      width: 150px; 
  }
  .banner img {
      height: 75px; 
  }
}

@media (max-width: 480px) {
  .banner {
      animation: scroll 40s linear infinite; /* Even slower scroll for very small screens */
  }
  .banner-item {
      width: 100px; 
  }
  .banner img {
      height: 50px; 
  }
}